.main {
    display: flex;
    flex-direction: column;
    padding: 66px 80px 133px 304px;
}
.text {
    font-size: 2.4rem;
    letter-spacing: -.01em;
    margin-bottom: 40px;
    text-transform: uppercase;
}
.detail-content {
    display: grid;
    gap: 32px 40px;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid gray;
    margin-bottom: 16px;
    padding-bottom: 48px;
}
.detail-content-text {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.detail-content-text h1 {
    font-weight: 700;
    font-size: 36px;
}
.detail-content-text  p {
    font-weight: 500;
    font-size: 26px;
}
.gh-card {
    max-width: 620px;
    width: 100%;
}
.gh-card-link {
    display: flex;
    gap: 24px;
}
.gh-card-image img {
    height: 100%;
    left: 0;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
}
.gh-card-wrapper {
    margin-top:"-3px"
}