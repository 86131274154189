.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    z-index: 99999999999;

  }
  
  .modal {
    background: url("../../assets/images/mapmodal.png");
    padding: 50px;
    border-radius: 5px;
    width: 50%;
   
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 1200px;
    min-width: 900px;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-wrap: nowrap;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .modal-body {
    margin-top: 20px;
  }
  
  .modal-actions {
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
  }
  
  .modal-button {
    padding: 10px 70px;
    margin: 5px;
    border: none;
    background-color: #7DBAC5; /* Default background color for button-components */
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .modal-footer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  @media (max-width: 1200px) { 
   .modal-header p {
    font-size: 28px;
   }
   .modal {
    min-width: 750px;
   }
  }

  @media (max-width: 900px) { 
    .modal-header p {
      font-size: 24px;
     }
     .modal {
      min-width: 650px;
     }
     .location-selector {
      min-width: 250px;
     }
   
  
  }
  @media (max-width: 786px) { 
    .modal-header p {
      font-size: 20px;
     }
     .modal {
      min-width: 550px;
     }
     .location-selector {
      min-width: 230px;
     }
   
    
  }
  @media (max-width: 600px) { 
    .modal-header p {
      font-size: 18px;
     }
     .modal {
      min-width: 450px;
     }
     .location-selector {
      min-width: 200px;
     }
   
    
  }
  @media (max-width: 500px) { 
    .modal-header p {
      font-size: 12px;
      margin-top: 20px;
     }
     .modal {
      min-width: 350px;
     }
     .location-selector {
      min-width: 150px;
     }
   
    
  }