.button-components {
    padding: 12px 16px 12px 16px;
    cursor: pointer;
    display: inline-flex; /* Use inline-flex for better alignment and fit-content behavior */
    align-items: center;
    justify-content: center; /* Center the text */
    background-color: #7DBAC5; /* Default background color for button-components */
    color: #fff; /* Default text color */
    border-radius: 6px;
    border: 2px solid transparent; 
    width: fit-content;
    position: relative; /* Needed for positioning pseudo-elements */
    overflow: hidden; /* Hide overflow to ensure the pseudo-element's gradient is contained */
    transition: color 250ms ease; /* Smooth transition for text color */
    text-wrap: nowrap;
    transition: all 0.3s ease; 
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;


  }