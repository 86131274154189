.feed-parent{
    margin-left: 280px;
}

@media screen and (max-width: 1024px) {
    .feed-parent {
       margin-left: 0px;
    }
}

.input-parent {
    max-width: 1480px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    padding-right: 30px;
}
.input-field-width {
    width: 55%;
}
@media screen and (max-width: 600px) {
    .input-field-width {
        width: unset;
    }
    .input-parent {
        padding-left: 20px;
    }
}
.responsive-div {
    margin-top: 50px;
    margin-left: 265px;
    margin-right: 70px;
  }
  
  @media (max-width: 600px) {
    .responsive-div {
      margin-left: 0;
      margin-right: 0;
      padding: 20px;
    }
    
  }